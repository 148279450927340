import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

import { TemplateContextProvider } from "components/post/TemplateProvider"
import { PlacesContextProvider } from "components/post/PlacesProvider"
import { useLocation } from "@reach/router"
import usePageLoading from "components/utils/usePageLoading"

import SEO from "../components/seo"
import Header from "components/header"
import LogoIcon from "../images/svg/logoIcon.svg"
import ImpactArea from "components/sections/impactArea"
import ImpactAreaFull from "components/sections/mockupImpactArea"

import Sections from "components/sections"
import Footer from "components/footer"

import "styles/pages.scss"
import "styles/sections.scss"

export const Head = ({ location, params, data, pageContext }) => {
  const pageData = data.allWpPage.edges[0].node
  const seo = pageData.seo
  const fallbackImage = ``

  const image = seo?.opengraphImage
    ? seo?.opengraphImage?.mediaItemUrl
    : fallbackImage

  return (
    <>
      <title>{pageContext.title}</title>
      <meta name="description" content={seo?.description} />
      <meta name="title" property={"og:title"} content={pageContext.title} />
      <meta name="image" property={"og:image"} content={image} />
      <meta
        name="twitter:url"
        content={`https://www.foobar.tld/${location.pathname}`}
      />
    </>
  )
}

const PageTemplate = ({ data }) => {
  const pageData = data.allWpPage.edges[0].node
  const [numPlaces, setNumPlaces] = useState([])

  const [pageClass, setPageClass] = useState("full")
  const [selectedSection, setSelectedSection] = useState(null)

  // TODO: This needs to be set from template context
  const [embedded, setEmbedded] = useState(false)
  const pageLoader = usePageLoading()

  const useFullImageImpact = pageData?.impactArea?.layout == "image"

  const location = useLocation()
  const businessPath = `businesses`
  const destinationsPath = `destinations`
  const partnerPath = `partners`
  const mockupPath = `mock-up`
  const isAltNaviation =
    useFullImageImpact ||
    location.pathname.includes(businessPath) ||
    location.pathname.includes(partnerPath) ||
    location.pathname.includes(mockupPath) ||
    location.pathname.includes(destinationsPath)

  return (
    <div
      className={`page ${pageClass} ${pageData.slug} ${pageLoader} ${
        embedded ? "embedded" : ""
      }`}
    >
      <TemplateContextProvider
        numPlaces={numPlaces}
        embeddedCallback={setEmbedded}
      >
        <PlacesContextProvider>
          <SEO lang="en-US" title={pageData.seo.title} data={pageData.seo} />
          {/* TODO: Make this a layout component with embed and section state */}
          <main>
            <Header />
            {isAltNaviation ? (
              <ImpactAreaFull data={pageData} />
            ) : (
              <ImpactArea data={pageData} />
            )}
            <div className="main container">
              {pageData.sections.sections && (
                <Sections
                  selected={selectedSection}
                  data={pageData.sections.sections}
                />
              )}

              {embedded && (
                <a
                  className="logoLink"
                  rel="dofollow"
                  href="https://vibemap.com"
                >
                  <LogoIcon />
                  <span>Find your vibe</span>
                </a>
              )}
            </div>
          </main>
          <Footer className={pageClass} />
        </PlacesContextProvider>
      </TemplateContextProvider>
    </div>
  )
}
export default PageTemplate

export const postQuery = graphql`
  query PageQuery($id: Int!) {
    allWpPage(filter: { databaseId: { eq: $id } }) {
      edges {
        node {
          id
          databaseId
          title
          date
          title
          slug
          seo {
            title
            opengraphAuthor
            focuskw
            canonical
            opengraphDescription
            metaDesc
            opengraphImage {
              mediaItemUrl
            }
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphTitle
            opengraphType
            opengraphUrl
          }
          impactArea {
            hideImpactArea
            textColor
            backgroundColor
            backgroundType
            backgroundImage {
              sourceUrl
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
            fullImageFields {
              bodyText
              fieldGroupName
              links {
                email
                externalUrl
                linkText
                linkType
                pageLink {
                  ... on WpPage {
                    id
                    uri
                  }
                  ... on WpPost {
                    id
                    uri
                  }
                }
              }
            }
            heading
            layout
            vibeset {
              ... on WpVibeset {
                id
                slug
                title
                databaseId
                vibesetDetails {
                  gradientImage {
                    mediaItemUrl
                  }
                  gradientVideo {
                    mediaItemUrl
                  }
                }
              }
            }
          }
          sections {
            fieldGroupName
            sections {
              ... on WpPage_Sections_Sections_HighlightBlock {
                bodyText
                highlights {
                  header
                  subheader
                  link
                  image {
                    localFile {
                      publicURL
                    }
                    mediaItemUrl
                  }
                }
                heading
                cardPerRow
                fieldGroupName
              }
              ... on WpPage_Sections_Sections_HomeImpactArea {
                bodyText
                fieldGroupName
                heading
                vibesets {
                  ... on WpVibeset {
                    slug
                    title
                    vibesetDetails {
                      exploreLink
                    }
                  }
                }
                links {
                  email
                  externalUrl
                  forceDownload
                  linkText
                  linkType
                  formId
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_AppStoreCallout {
                text
                appStoreUrl
                fieldGroupName
                googlePlayUrl
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_AuthorBlock {
                fieldGroupName
                author {
                  name
                  nicename
                  nickname
                  description
                  seo {
                    social {
                      instagram
                      facebook
                      twitter
                    }
                  }
                  url
                  profile_image {
                    profileImage {
                      mediaItemUrl
                    }
                  }
                }
                blockName
                customFullName
                customProfileDescription
                customProfileImage {
                  mediaItemUrl
                }
              }
              ... on WpPage_Sections_Sections_CalloutBlock {
                fieldGroupName
                heading
                bodyText
                style
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  formId
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_CitiesBlock {
                heading
                bodyText
                cardPerRow
                imageType
                fieldGroupName
                cities {
                  ... on WpCity {
                    id
                    name: title
                    title
                    uri
                    cityDetails {
                      icon {
                        databaseId
                        localFile {
                          publicURL
                        }
                      }
                      photos {
                        mediaItemUrl
                      }
                      placemarker {
                        city
                      }
                      vibes {
                        slug
                        name
                      }
                    }
                  }
                }
                links {
                  externalUrl
                  forceDownload
                  linkText
                  linkType
                  formId
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_FeaturedPost {
                fieldGroupName
                automatic
                heading
                featuredPost {
                  ... on WpPost {
                    id
                    databaseId
                    title
                    slug
                    uri
                    date
                    content
                    excerpt
                    featuredImage {
                      node {
                        caption
                        mediaItemUrl
                      }
                    }
                    author {
                      node {
                        name
                      }
                    }
                    categories {
                      nodes {
                        name
                      }
                    }
                    postDetails {
                      authorName
                      authorBio
                      customAuthor
                      vibeset {
                        ... on WpVibeset {
                          id
                          title
                          databaseId
                          vibesetDetails {
                            gradientImage {
                              mediaItemUrl
                            }
                            gradientVideo {
                              mediaItemUrl
                            }
                          }
                        }
                      }
                      city {
                        ... on WpCity {
                          id
                          title
                          uri
                          slug
                          databaseId
                        }
                      }
                    }
                  }
                }
                posts {
                  ... on WpPost {
                    id
                    databaseId
                    title
                    slug
                    uri
                    date
                    content
                    excerpt
                    featuredImage {
                      node {
                        caption
                        mediaItemUrl
                      }
                    }
                    author {
                      node {
                        name
                      }
                    }
                    categories {
                      nodes {
                        name
                      }
                    }
                    postDetails {
                      vibeset {
                        ... on WpVibeset {
                          id
                          title
                          databaseId
                          vibesetDetails {
                            gradientImage {
                              mediaItemUrl
                            }
                            gradientVideo {
                              mediaItemUrl
                            }
                          }
                        }
                      }
                      city {
                        ... on WpCity {
                          id
                          title
                          uri
                          slug
                          databaseId
                        }
                      }
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_Affilate {
                code
                fieldGroupName
                heading
              }
              ... on WpPage_Sections_Sections_Embed {
                embedCode
                fieldGroupName
                heading
              }
              ... on WpPage_Sections_Sections_MapBlock {
                fieldGroupName
                heading
                height
                location {
                  ... on WpCity {
                    id
                    cityDetails {
                      placemarker {
                        latitude
                        longitude
                        city
                        country
                        state
                      }
                    }
                  }
                  ... on WpNeighborhood {
                    id
                    neighborhood {
                      map {
                        latitude
                        longitude
                      }
                    }
                  }
                }
                map {
                  latitude
                  longitude
                }
                openInApp
                directions
                heatmap
                map3d
                mapTheme
                showMarkers
                markerStyle
                zoom
                width
              }
              ... on WpPage_Sections_Sections_SingCardsBlock {
                fieldGroupName
                singCards {
                  fieldGroupName
                  heading
                  posts {
                    description
                    categoryQuery
                    city
                    distanceQuery
                    editorialCategoryQuery
                    fieldGroupName
                    geoQuery {
                      latitude
                      longitude
                    }
                    image
                    note
                    numOfPlaces
                    placeId
                    placeType
                    searchQuery
                    subcategory
                    type
                    title
                    vibes
                    vibeQuery {
                      name
                      slug
                    }
                  }
                  showAll
                  showAllRelation
                  showAllText
                  subheading
                  style
                  vibemapSuggests
                }
              }
              ... on WpPage_Sections_Sections_QuotesBlock {
                fieldGroupName
                heading
                quotes {
                  attribution
                  fieldGroupName
                  quote
                }
              }
              ... on WpPage_Sections_Sections_VibeCheckBlock {
                fieldGroupName
                heading
                form
              }
              ... on WpPage_Sections_Sections_ImageBlock {
                fieldGroupName
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_StoryBlock {
                fieldGroupName
                blocks {
                  bodyText
                  style
                  caption
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED)
                      }
                    }
                  }
                  vibeset {
                    ... on WpVibeset {
                      id
                      vibesetDetails {
                        gradientImage {
                          mediaItemUrl
                        }
                        gradientVideo {
                          mediaItemUrl
                        }
                      }
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_FormBlock {
                fieldGroupName
                form
                heading
                text
              }
              ... on WpPage_Sections_Sections_TextWithForm {
                fieldGroupName
                heading
                bodyText
                layout
                form
              }
              ... on WpPage_Sections_Sections_TextColumnsIcon {
                fieldGroupName
                columns {
                  bodyText
                  fieldGroupName
                  heading
                  iconSize
                  icon {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED)
                      }
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_TextWithImage {
                bodyText
                fieldGroupName
                heading
                layout
                textStyles
                style
                caption
                image {
                  altText
                  caption
                  localFile {
                    publicURL
                  }
                }
                vibeset {
                  ... on WpVibeset {
                    id
                    slug
                    vibesetDetails {
                      gradientImage {
                        mediaItemUrl
                      }
                      gradientVideo {
                        mediaItemUrl
                      }
                    }
                  }
                }
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  formId
                  email
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_TextWithBlock {
                bodyText
                fieldGroupName
                heading
                layout
                textStyles
                style
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  formId
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
                embed: embedBlock {
                  embedCode
                  heading
                }
                blockType
                mapBlock: mapContainer {
                  directions
                  heatmap
                  height
                  location {
                    ... on WpCity {
                      id
                      cityDetails {
                        placemarker {
                          latitude
                          longitude
                        }
                      }
                    }
                    ... on WpNeighborhood {
                      id
                      neighborhood {
                        map {
                          latitude
                          longitude
                        }
                      }
                    }
                  }
                  map {
                    latitude
                    longitude
                  }
                  map3d
                  mapTheme
                  markerStyle
                  openInApp
                  showMarkers
                  width
                  zoom
                }
              }
              ... on WpPage_Sections_Sections_TextWithImageForm {
                fieldGroupName
                heading
                bodyText
                form
                image {
                  altText
                  caption
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
                layout
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  formId
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_TextWithImageCallout {
                fieldGroupName
                heading
                bodyText
                layout
                image {
                  altText
                  caption
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  formId
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
                callout {
                  heading
                  bodyText
                  calloutLinks {
                    externalUrl
                    fieldGroupName
                    forceDownload
                    linkText
                    linkType
                    formId
                    pageLink {
                      ... on WpPage {
                        id
                        uri
                      }
                      ... on WpPost {
                        id
                        uri
                      }
                    }
                    file {
                      id
                      localFile {
                        name
                        ext
                        publicURL
                      }
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_LinkCardsBlock {
                fieldGroupName
                heading
                links {
                  link
                  image {
                    id
                    databaseId
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED)
                      }
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_VibesetsBlock {
                bodyText
                fieldGroupName
                heading
                vibesets {
                  vibeset {
                    ... on WpVibeset {
                      id
                      title
                      databaseId
                      vibesetDetails {
                        exploreLink
                        tagline
                        description
                        mainVibes
                        gradientImage {
                          mediaItemUrl
                        }
                        gradientVideo {
                          mediaItemUrl
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
