import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useTemplateContext } from 'components/post/TemplateProvider'

import Gradient from "components/shared/gradient"
import HyperLinks from "components/hyperlinks"

import * as style_variables from 'vibemap-constants/design-system/build/json/variables.json'
const vibe_styles = style_variables['default']['color']['vibes']

import 'components/shared/styles/impactArea/impactArea.scss'


const ImpactAreaFull = ({ data, showCityTitle }) => {
  const pageContext = useTemplateContext()
  const { currentCity } = pageContext

  if (data.impactArea.hideImpactArea) return null

  const vibeset = data?.impactArea?.vibeset?.slug
  const [color1, setColor1] = useState(vibe_styles['dreamy']['primary'])
  const [color2, setColor2] = useState(vibe_styles['dreamy']['secondary'])
  useEffect(() => {
    if (vibeset && vibe_styles[vibeset]) {
      setColor1(vibe_styles[vibeset]['primary'])
      setColor2(vibe_styles[vibeset]['secondary'])
    }

  }, [vibeset])

  const city = data.impactArea.city
  const backgroundType = data?.impactArea?.backgroundType

  const renderMockupBackground = (data) => {
    const backupImage = `https://cms.vibemap.com/wp-content/uploads/2020/06/Dreamy000-compressed-11zon-min.jpg`;

    const backgroundImage = data && data.impactArea && data.impactArea.vibeset && data.impactArea.vibeset.vibesetDetails
      ? data.impactArea.vibeset.vibesetDetails.gradientImage.mediaItemUrl
      : backupImage

    const featuredImage = data.impactArea && data.impactArea.backgroundImage
      ? data.impactArea.backgroundImage.sourceUrl
      : ''
    switch (data.impactArea.backgroundType) {
      case "color":
        return (
          <div
            className={`fill color-fill c-${data.impactArea.backgroundColor}`}
          ></div>
        )

      case "image":
        return (
          <div
            className={`fill image-fill`}
            style={{
              backgroundImage: `url(${featuredImage})`,
            }}
          ></div>
        )

      case "vibe_static":
        return (
          <div
            className={`fill image-fill`}
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          ></div>
        )

      case "vibe_dynamic":
        return (
          <div className={`fill video-fill`}>
            <div className='vibeBackground'>
              <Gradient
                color1={color1}
                color2={color2} />
            </div>
          </div>
        )
    }
  }
  const renderImpactArea = (data) => {

    return (
      <>
        {data && data.impactArea && data.impactArea.heading
          ?
          <div className={`container ${data.impactArea.backgroundType} container_height`}>
            <div className={`impact-area-content`} >
              <h1>
                {data.impactArea.heading}
              </h1>
              <p> {data && data.impactArea && data.impactArea.fullImageFields ? data.impactArea.fullImageFields.bodyText : ''} </p>
              {data?.impactArea?.fullImageFields?.links
                ? <HyperLinks
                    buttons={true}
                    data={data?.impactArea?.fullImageFields?.links}
                    hasChevron={false} />
                : null }
            </div>
          </div>
          :
          <div className={`container ${data.impactArea.backgroundType}`}>
            <h1 className={`title c-${data.impactArea.textColor}`}>
              <span>
                {data.title
                }
              </span>
            </h1>
          </div>
        }

      </>
    )

  }
  return (
    <div className={`s-impact-area ${backgroundType}`}>
      {renderImpactArea(data)}
      <div className="background">
        {data && data.impactArea && renderMockupBackground(data)}
      </div>
    </div>
  )
}
export default ImpactAreaFull
